// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-advertising-promotional-js": () => import("./../../../src/pages/services/advertising-promotional.js" /* webpackChunkName: "component---src-pages-services-advertising-promotional-js" */),
  "component---src-pages-services-digital-marketing-js": () => import("./../../../src/pages/services/digital-marketing.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-js" */),
  "component---src-pages-services-events-exhibitions-js": () => import("./../../../src/pages/services/events-exhibitions.js" /* webpackChunkName: "component---src-pages-services-events-exhibitions-js" */),
  "component---src-pages-services-graphic-design-js": () => import("./../../../src/pages/services/graphic-design.js" /* webpackChunkName: "component---src-pages-services-graphic-design-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-photography-videography-js": () => import("./../../../src/pages/services/photography-videography.js" /* webpackChunkName: "component---src-pages-services-photography-videography-js" */),
  "component---src-pages-services-printing-services-js": () => import("./../../../src/pages/services/printing-services.js" /* webpackChunkName: "component---src-pages-services-printing-services-js" */),
  "component---src-pages-services-website-mobile-app-development-js": () => import("./../../../src/pages/services/website-mobile-app-development.js" /* webpackChunkName: "component---src-pages-services-website-mobile-app-development-js" */)
}

